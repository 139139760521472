import Swiper from 'swiper/bundle';

// import styles bundle
// import 'swiper/css/bundle';

import '../../node_modules/aos/dist/aos.js';
import AOS from 'aos';


AOS.init();

const swiper = new Swiper('.swiper', {
    // Optional parameters
    loop: true,
  
    // If we need pagination
    pagination: {
      el: '.swiper-pagination',
    },

    slidesPerView: 1,
  
    // Navigation arrows

  
    // And if we need scrollbar
    scrollbar: {
      el: '.swiper-scrollbar',
    },

    breakpoints: {
      576: {
        slidesPerView: 2,
      },

      1100: {
        slidesPerView: 4
      }

    }
  });